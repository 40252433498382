<template>
  <div class="news">
    <div class="box1">
      <div class="text">
        <div class="txt">Company News</div>
        <!-- <div class="txt2">News Tendency</div> -->
      </div>
    </div>
    <div class="box2">
      <!-- <div class="flex-sp top">
        <ul class="flex">
          <router-link tag="li" to="/news/company">公司新闻</router-link>
          <router-link tag="li" to="/news/product">产品动态</router-link>
          <router-link tag="li" to="/news/bazaar">市场活动</router-link>
          <router-link tag="li" to="/news/world">恒生世界</router-link>
        </ul>
        <el-input class="inpt" placeholder="搜索相关资讯" v-model="keyword" @change="search"></el-input>
      </div> -->
      <div class="content">
        <keep-alive v-if="$route.meta.cache">
          <router-view></router-view>
        </keep-alive>
        <router-view v-else></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyword: "",
    };
  },
  mounted() {
    //获取到配置的头部颜色，听过store监听动态改变
    this.$store.commit("until/SET_COLOR", "#000");
  },
  methods: {
    search() {
      // this.$router.push({path:'/',query:{kw:this.keyword}})
    },
    // getdata(){
    //   this.$fetch({
    //     url:"index.php/news/cpdt",
    //     type:"post",
    //     data:this.$qs.stringify({keyword:this.keyword})
    //   }).then((res)=>{
    //     console.log(res,"55555555555555");
    //   })
    // }
  },
};
</script>

<style scoped>
.box1 {
  height: 320px;
  background: url("../assets/img01/news1.png") center center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box1 .text {
  text-align: center;
  margin-top: 45px;
}
.box1 .text .txt {
  font-size: 36px;
  font-weight: 400;
  color: #333333;
  padding-bottom: 5px;
}
.box1 .text .txt2 {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 20px;
}
.box2 {
  padding-top:50px;
}
.box2 .inpt {
  width: 240px;
  height: 40px;
  position: relative;
}
.box2 .inpt::after {
  content: "";
  background-image: url(../assets/images/h-sousuo.png);
  width: 18px;
  height: 18px;
  position: absolute;
  top: 10px;
  right: 15px;
  background-size: cover;
}
.box2 .top {
  width: 100%;
  background: #fff;
  /* position: sticky;
  top: 70px; */
  padding: 50px 0;
  margin: 0 auto;
  z-index: 1;
}
.box2 .top ul {
  width: 1200px;
  margin: 0 auto;
}
.box2 .top ul li {
  margin-right: 92px;
  cursor: pointer;
  font-weight: 600;
}
.box2 .top ul li:hover{
  color: #396aff;
}
.box2 .flex1 li {
  margin-right: 92px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.router-link-active {
  color: #396aff;
}

@media (min-width: 1280px) {
  .box1 {
    height: 320px;
    background: url("../assets/img01/news1.png") center center/cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box1 .text {
    text-align: center;
    margin-top: 45px;
  }
  .box1 .text .txt {
    font-size: 36px;
    font-weight: 400;
    color: #333333;
    padding-bottom: 5px;
  }
  .box1 .text .txt2 {
    font-size: 16px;
    font-weight: 400;
    color: #999;
    line-height: 20px;
  }
  .box2 .inpt {
    width: 240px;
    height: 40px;
    position: relative;
  }
  .box2 .inpt::after {
    content: "";
    background-image: url(../assets/images/h-sousuo.png);
    width: 18px;
    height: 18px;
    position: absolute;
    top: 10px;
    right: 15px;
    background-size: cover;
  }
  .box2 .top {
    width: 100%;
    background: #fff;
    /* position: sticky;
    top: 70px; */
    padding: 50px 0;
    margin: 0 auto;
    z-index: 1;
  }
  .box2 .top ul {
    width: 1200px;
    margin: 0 auto;
  }
  .box2 .top ul li {
    margin-right: 92px;
  }
  .box2 .flex1 li {
    margin-right: 92px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
  .router-link-active {
    color: #396aff;
  }
}

@media (max-width: 1367px) and (min-width: 1280px) {
  .box1 {
    height: calc(320px * 0.9);
    background: url("../assets/img01/news1.png") center center/cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box1 .text {
    text-align: center;
    margin-top: calc(45px * 0.9);
  }
  .box1 .text .txt {
    font-size: 36px;
    font-weight: 400;
    color: #333333;
    padding-bottom: calc(5px * 0.9);
  }
  .box1 .text .txt2 {
    font-size: 16px;
    font-weight: 400;
    color: #999;
    line-height: calc(20px * 0.9);
  }
  .box2 .inpt {
    width: calc(240px * 0.9);
    height: calc(40px * 0.9);
    position: relative;
  }
  .box2 .inpt::after {
    content: "";
    background-image: url(../assets/images/h-sousuo.png);
    width: calc(18px * 0.9);
    height: calc(18px * 0.9);
    position: absolute;
    top: calc(10px * 0.9);
    right: calc(15px * 0.9);
    background-size: cover;
  }
  .box2 .top {
    width: 100% ;
    background: #fff;
    /* position: sticky;
    top: calc(70px * 0.9); */
    padding: calc(50px * 0.9) 0;
    margin: 0 auto;
    z-index: 1;
  }
  .box2 .top ul {
    width: calc(1200px * 0.9);
    margin: 0 auto;
  }
  .box2 .top ul li {
    margin-right: calc(92px * 0.9);
  }
  .box2 .flex1 li {
    margin-right: calc(92px * 0.9);
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
  .router-link-active {
    color: #396aff;
  }
}
</style>